<template>
  <div>
    <h1>Page not found</h1>
    <p class="danger">Error - route not found</p>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
