import Vue from 'vue'
import VueRouter from 'vue-router'
import Tools from '../views/Tools.vue'
import Projects from '../views/Projects.vue'
import Downloads from '../views/Downloads.vue'
import Genomics from '../views/Genomics.vue'
import Team from '../views/Team.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/',
    name: 'homePage',
    component: Tools
  },
  {
    path: '/tools',
    name: 'tools',
    component: Tools
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads
  },
  {
    path: '/genomics',
    name: 'genomics',
    component: Genomics
  },
  {
    path: '/team',
    name: 'team',
    component: Team
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  }
]

const router = new VueRouter(
  {
    linkActiveClass: 'is-active',
    routes
  })

export default router
