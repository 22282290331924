<template>
  <div>
    <div class="columns  is-multiline">
      <resource-list-item
        v-for="resource in resources.filter(resource => resource.pages.includes('plants'))"
        :key="resource.id"
        :resource="resource"
      ></resource-list-item>
      <resource-list-item
        v-for="resource in resources.filter(resource => resource.pages.includes('bacteria'))"
        :key="resource.id"
        :resource="resource"
      ></resource-list-item>
      <resource-list-item
        v-for="resource in resources.filter(resource => ! resource.pages.includes('bacteria')).filter(resource => ! resource.pages.includes('plants'))"
        :key="resource.id"
        :resource="resource"
      ></resource-list-item>
    </div>
  </div>
</template>

<script>
import ResourceListItem from '@/components/ResourceListItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Genomics',
  components: {
    ResourceListItem
  },
  computed: {
    ...mapGetters({ resources: 'getGenomics' })
  }
}
</script>

<style>
</style>
