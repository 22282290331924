<template>
  <div id="app">
    <section class="hero is-light is-small">
      <div class="hero-body">
        <div class="container has-text-centered">
        <div class="columns">
          <div class="column">
          <img
            src="img/inrae.png"
            width="80"
          />
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column">
          <a href="https://www.lipme.fr">
            <img
              src="img/lipme.png"
              alt="Logo"
              width="60"
            />
          </a>
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column">
          <img
            src="img/cnrs.png"
            width="30"
          />
          </div>
          </div>
          <p class="title">
            LIPME Bioinformatics Platform
            <br />
            <span class="tag is-success">lipme-bioinfo@inrae.fr</span>
          </p>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="tabs">
        <ul>
          <router-link
            active-class="is-active"
            to="/tools"
          >Resources</router-link>
          <router-link
            active-class="is-active"
            to="/genomics"
          >Genomics</router-link>
          <router-link
            active-class="is-active"
            to="/team"
          >Team</router-link>
          <router-link
            active-class="is-active"
            to="/projects"
          >Projects</router-link>
          <li>
            <a
              href="http://lipm-bioinfo.toulouse.inra.fr/bibteam/"
              target="bibteam"
            >Publications</a>
          </li>
          <router-link
            active-class="is-active"
            to="/downloads"
          >Downloads</router-link>
        </ul>
      </div>
      <div class="columns is-multiline">
        <div v-if="!ready"> Loading </div>
        <div v-else>

          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions({ getResourcesFromServer: 'recupererResources' })
  },
  data () {
    return {
      ready: false
    }
  },
  created () {
    this.getResourcesFromServer()
      .then(() => { this.ready = true })
      .catch(err => {
        console.log('Erreur de recuperation des resources', err)
      })
      .finally(() => {
        this.ready = true
      })
  }
}
</script>

<style>
@import "../node_modules/bulma/css/bulma.css";
</style>
