import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    resources: []
  },
  getters: {
    // ~computed
    getProjects (state) {
      return state.resources.filter(resource => resource.pages.includes('projects'))
    },
    getTools (state) {
      return state.resources.filter(resource => resource.pages.includes('tools'))
    },
    getTeam (state) {
      return state.resources.filter(resource => resource.pages.includes('team'))
    },
    getGenomics (state) {
      return state.resources.filter(resource => resource.pages.includes('genomics'))
    },
    getDownloads (state) {
      return state.resources.filter(resource => resource.pages.includes('downloads'))
    }
  },
  mutations: {
    definirResources (state, resources) {
      state.resources = resources
    }
  },
  actions: {
    recupererResources (context) {
      return new Promise((resolve, reject) => {
        axios.get('api/resources.json')
          .then(res => {
            let resources = res.data['resources']
            const sorter = (x, y) => y.top - x.top || x.id - y.id
            context.commit('definirResources', resources.sort(sorter))
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  modules: {}
})
